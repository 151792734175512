'use client'

import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { object, string } from 'yup'

export default function Newsletter () {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    return () => {
      setLoading(false)
      setError(null)
      setSubmitted(false)
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: object({
      email: string()
        .email('Turi būti el. pašto adresas')
        .max(255)
        .required('El. pašto adresas yra būtinas')
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const response = await fetch('https://api4.weido.app/dev/subscribe', {
          method: 'POST',
          body: JSON.stringify({ email: values.email }),
          headers: {
            Accept: 'application/json'
          }
        })

        const result = await response.json()
        if (result.status !== 'ok') {
          setError('Klaida')
          setLoading(false)
        } else {
          setSubmitted(true)
          setLoading(false)
        }
      } catch (e) {
        setError(e.message)
        setLoading(false)
      }
    }
  })

  return (
    <div className="grid justify-centeralign-self-center">
      <section
        id="naujienos"
        className="newsletter container_padding centered_wrapper">
        <h3 className="">Sek Weido naujienas!</h3>

        {!submitted ? <div id="mc_embed_signup">
          <form
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            onSubmit={formik.handleSubmit}
            noValidate>
            <div id="mc_embed_signup_scroll">
              <input
                type="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="email"
                value={formik.values.email}
                className="email"
                id="mce-EMAIL"
                placeholder="Įveskite savo el.pašto adresą"
                required />
              <label htmlFor="mce-EMAIL">Prisijunk ir sek mūsų naujienas.</label>

              {!loading && <div className="clear">
                <input
                  type="submit"
                  value="Užsirašyti"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  onClick={formik.handleSubmit}
                  className="button" />
              </div>}
            </div>
          </form>
        </div> : null}
        {!!error && <p>{error}</p>}
      </section>
    </div>

  )
}
