import React from 'react'

export default function DownloadButtons () {
  return (
    <div className="links" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <a
          href="#naujienos"
          className="apple download_iOS_mobile_app"
          title="Weido jau greitai App Store"
          aria-label="iOS Button Link">
          <img
            width="185"
            height="61"
            className="ios_appstore-btn"
            src="/s/homepage/ios_appstore-btn.png"
            alt="Weido jau greitai App Store" />
        </a>
      </div>
      <div>
        <a
          href="#naujienos"
          className="google download_android_mobile_app"
          title="Weido jau greitai Google Play"
          aria-label="Android Button Link" >
          <img
            width="203"
            height="60"
            className="android_googleplay-btn"
            src="/s/homepage/android_googleplay-btn.png"
            alt="Weido jau greitai Google Play" />
        </a>
      </div>
    </div>
  )
}
