import React from 'react'

export default function Benefits () {
  return (
    <section id="benefits">
      <div className="grid justify-space-between">
        <div className="col-12 col-sm-6 col-md-3 align-self-top">
          <div className="benefit">
            <div className="icon">
              <img
                src="/s/homepage/weidoApp.svg"
                width="92"
                height="92"
                alt="Weido yra ekologiškas"
                type="image/svg+xml" />
            </div>
            <h4>Atsisiųsk Weido</h4>
            <p className="p_smaller">
              Ir pamiršk apie daiktų pirkimą, nuoma tai ateitis
            </p>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-3 align-self-top">
          <div className="benefit">
            <div className="icon">
              <img
                src="/s/homepage/weidoPaieska.svg"
                width="92"
                height="92"
                alt="Weido yra ekologiškas"
                type="image/svg+xml" />
            </div>
            <h4>Rask ko ieškai</h4>
            <p className="p_smaller">
              Weido padės tai atlikti vos per keletą sekundžių
            </p>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-3 align-self-top">
          <div className="benefit">
            <div className="icon">
              <img
                src="/s/homepage/weidoPristatymas.svg"
                width="92"
                height="92"
                alt="Weido yra ekologiškas"
                type="image/svg+xml" />
            </div>
            <h4>Išsinuomok</h4>
            <p className="p_smaller">
              Daiktai patys atvyks pas tave, neiškėlus kojos iš namų
            </p>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-3 align-self-top">
          <div className="benefit">
            <div className="icon">
              <img
                src="/s/homepage/weidoNuoma.svg"
                width="92"
                height="92"
                alt="Weido yra ekologiškas"
                type="image/svg+xml" />
            </div>
            <h4>Mėgaukis patirtimi</h4>
            <p className="p_smaller">
              Weido pasirūpins tavo saugumu, sutartimis ir sandorių sklandumu.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
