import React from 'react'

import DownloadButtons from './downloadButtons'

export default function Hero () {
  return (
    <section className="hero">
      <div className="container header_padding">
        <div className="grid">
          <div className="col-xs-12 col-md-6 col-xlg-5 order-md-1 align-self-center">
            <div className="copy">
              <h1>Taupyk laiką,<br />bet ne daiktus</h1>
              <p className="p_larger">
                Nuomokis ir išnuomok per Weido.
                <br />Visi daiktai kurių tau reikia dabar yra tavo telefone.
                <br /><br />
              </p>
              <DownloadButtons />
            </div>
          </div>
          <div
            className="
              col-xs-12 col-md-6 col-xlg-7
              order-md-2
              align-self-end">
            <picture>
              <source
                src="/s/homepage/heroHome.webp"
                type="image/webp" />
              <img
                src="/s/homepage/heroHome.png"
                width="1014"
                height="721"
                alt="Weido daiktų, įrankių ir transporto nuoma" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  )
}
