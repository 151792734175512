import React from 'react'

import Newsletter from './newsletter'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

export default function Footer () {
  return (
    <footer id="footer" role="contentinfo">
      <div className="container container_padding">
        <Newsletter />
        <div className="footer grid align-self-center align-center justify-center">
          <div className="col">
            <div className="footer__logo">
              {/*
              <a href="/daiktu-nuoma">
                <img
                  src="/s/homepage/weido-icon.png"
                  width="145"
                  height="55"
                  alt="Weido.lt" />
              </a>
              */}
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div><a target="blank" href="https://www.facebook.com/Weido.lt/"><FacebookIcon /></a></div>
                <div><a target="blank" href="https://x.com/WeidoShare"><TwitterIcon /></a></div>
                <div><a target="blank" href="https://www.instagram.com/weido.lt/"><InstagramIcon /></a></div>
                <div><a target="blank" href="https://www.linkedin.com/company/eido-technologijos/?originalSubdomain=lt"><LinkedInIcon /></a></div>
              </div>
            </div>
            <small> <a href="/daiktu-nuoma">Weido - Daiktų, įrankių ir transporto nuoma</a><br></br></small>
            <div>
              <small style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                © {new Date().getFullYear()} <a rel="external" target="blank" href="http://weido.app">Eido technologijos UAB</a>. Visos teisės saugomos.
              </small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
