import React from 'react'
import PropTypes from 'prop-types'

export default function Banner ({ bannerTitle, bannerText1, bannerText2, buttonText, buttonLink }) {
  return (
    <section className="cta">
      <div className="container container_padding">
        <div className="grid">
          <div className="col-12 col-lg-9 align-self-center">
            <div className="copy content__padding">
              <h3>{ bannerTitle }</h3>
              <p className="p_smaller">{ bannerText1 }<br />{ bannerText2 }</p>
            </div>
          </div>
          <div className="col-12 col-lg-3 align-self-end">
            <a href={buttonLink}><button className="button">{ buttonText }</button></a>
          </div>
        </div>
      </div>
    </section>
  )
}

Banner.propTypes = {
  bannerTitle: PropTypes.string,
  bannerText1: PropTypes.string,
  bannerText2: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string
}
