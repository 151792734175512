import React, { memo } from 'react'

import WeidoLogo from './logo'

export default memo(function Nav () {
  return (
    <nav className="main-nav" role="navigation">
      <div className="container grid justify-space-between">
        <a href="/" className="logo align-self-center" title="Weido Logotipas" aria-label="Logo" >
          <WeidoLogo />
        </a>
        <ul className="align-self-center justify-end">
          <li className="hidden-xxs">
            <a href="/">Pradžia</a>
          </li>
          {/*
          <li>
            <a href="/about">Apie mus</a>
          </li>
          */}
          <li>
            <div>
              <a href="/sign-in"><div className="button fill">Prisijungti</div></a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  )
})
