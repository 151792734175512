import React from 'react'

import CategoryBox from './categoryBox'

export default function Categories () {
  return (
    <section id="categories">
      <div className="grid justify-center">
        <CategoryBox categoryTitle="Foto, video" imageName="foto-video.png" />
        <CategoryBox categoryTitle="Sportas" imageName="sportas.png" />
        <CategoryBox categoryTitle="Konsolės" imageName="konsoles.png" />
        <CategoryBox categoryTitle="Dronai" imageName="dronai.png" />
        <CategoryBox categoryTitle="Transportas" imageName="transportas.png" />
        <CategoryBox categoryTitle="Turizmas" imageName="turizmas.png" />
        <CategoryBox categoryTitle="Audio" imageName="audio.png" />
        <CategoryBox categoryTitle="Elektronika" imageName="elektronika.png" />
        <CategoryBox categoryTitle="Renginiai" imageName="renginiai.png" />
        <CategoryBox categoryTitle="Buitis" imageName="buitis.png" />
        <CategoryBox categoryTitle="Statybos" imageName="statybos.png" />
        <CategoryBox categoryTitle="Ūkis, sodas" imageName="ukis-sodas.png" />
        <CategoryBox categoryTitle="Vintažas" imageName="vintazas.png" />
        <CategoryBox categoryTitle="Drabužiai" imageName="drabuziai.png" />
        <CategoryBox categoryTitle="Vaikams" imageName="vaikams.png" />
        <CategoryBox categoryTitle="Medįranga" imageName="mediranga.png" />
      </div>
    </section>
  )
}
