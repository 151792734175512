import React from 'react'

import Hero from './hero'
import Benefits from './benefits'
import AboutUs from './aboutUs'
import Categories from './categories'
import Banner from './banner'

export default function Homepage () {
  return (
    <div>
      <div id="home_page">
        <Hero />
        <section className="content__top">
          <Benefits class="container container_padding" />
        </section>

        <AboutUs />

        <section className="container container_padding">
          <h3 className="categoryTitle">Pas mus galėsite nuomoti</h3>
          <Categories />
          <Banner
            bannerTitle="Nori užsidirbti iš savo daiktų?"
            bannerText1="Weido tai pats saugiausias įrankis įdarbinti
                savo daiktus ir sutaupyti pinigų atostogoms."
            bannerText2="Susisiek su mumis dar
                šiandien ir tapk vienu pirmųjų mūsų klientų."
            buttonText="Susisiek su mumis"
            buttonLink="https://us2.list-manage.com/contact-form?u=2e33e413afe3a9a70b3d5b482&form_id=368126d43ea2350a1400e30854ae43c9"
          />
        </section>
      </div>
    </div>
  )
}
