'use client'

import React from 'react'
import PropTypes from 'prop-types'

import Nav from 'components/homepage/nav'
import Footer from 'components/homepage/footer'
import HomePage from 'components/homepage'

export default function Home ({ params: { lang } }) {
  return (
    <div>
      <Nav />
      <div>
        <HomePage />
        <Footer />
      </div>
    </div>
  )
}

Home.propTypes = {
  searchParams: PropTypes.object,
  params: PropTypes.object
}
