import React from 'react'
import PropTypes from 'prop-types'

export default function CategoryBox ({ categoryTitle, categoryLink, imageName }) {
  return (
    <div className="col-3 col-sm-3 col-lg-2">
      <a href={categoryLink ?? '/'}><div id="categories__box">
          <div className="categories__pic">
            <picture>
              <img
                src={`/s/homepage/categories/${imageName}`}
                width="220"
                height="220"
                alt="Weido nuomos kategorija" />
            </picture>
          </div>
          <div className="categories__info">
            <h5>{ categoryTitle }</h5>
          </div>
        </div>
      </a>
    </div>
  )
}

CategoryBox.propTypes = {
  categoryTitle: PropTypes.string,
  imageName: PropTypes.string,
  categoryLink: PropTypes.string
}
