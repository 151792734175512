import React from 'react'

export default function AboutUs () {
  return (
    <section className="aboutus">
      <div className="container">
        <div className="grid">
          <div className="hidden-xs-down col-md-6 order-md-2 align-self-center">
            <div className="copy container_padding">
              <h2>Draugiška daiktų nuomos platforma</h2>
              <p className="p__larger">
                Weido ne tik efektyvus įrankis uždirbti ir taupyti pinigus, bet ir
                galimybė prisidėti prie ekologiško ir tvaresnio pasaulio
                kūrimo.<br />
                <a href="/about">Skaityti daugiau</a>
              </p>
            </div>
          </div>
          <div className="hidden-xs-down col-md-6 order-md-1">
            <picture className="skew-img">
              <source
                src="/s/homepage/weido-apie_vizija.webp"
                type="image/webp" />
              <img
                src="/s/homepage/weido-apie_vizija.png"
                width="1014"
                height="721"
                alt="Senolis optimistiškai nusiteikęs dėl ateities"
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  )
}
